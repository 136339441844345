import { Button, Flex, Heading, Input, Link, Wrapper } from 'atoms';
import { MfaTelValidate } from 'components/user-mfa/mfa-setup/mfa-validate';
import { Box, Form as F } from 'grommet';
import fetch from 'node-fetch';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { checkAuth, login } from 'services/auth/login';
import { loadingContext } from 'services/ui';
import styled, { css } from 'styled-components';

const Message = styled.div`
  display: flex;
  margin: 1.3rem 0.8rem;
`;

const Form = styled(F)`
  display: ${({ visible }) => (visible === 'true' ? 'block' : 'none')};
`;

const ResetResponse = styled.div`
  justify-content: center;
  flex-direction: column;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
`;

const FormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(${({ visible }) => (visible ? '0' : '-100vw')});
  transition-delay: ${({ visible }) => (visible ? '0.25s' : '0.5s')};
  transition: 0.5s all ease-in-out;
  padding-top: 15%;
  display: block;
  justify-content: center;
  min-width: 20rem;
  width: 80%;
  max-width: 400px;
  margin: auto;
`;

const LoginMessageContainer = styled.div`
  min-width: 20rem;
  width: 80%;
  background-color: #ffe4e4;
  max-width: 400px;
  margin: auto;
  font-size: 1rem;
  z-index: 10;
  margin-top: 10%;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 0.7rem;
`;

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  text-align: end;
`;

const FormTransition = styled.div`
  position: relative;
  z-index: 1;
`;
const Transition = styled.div`
  top: 0;
  left: 0;
  position: ${props => props.position || 'absolute'};
  z-index: ${props => props.zIndex || '1'};
  transition: ${props => `${props.duration || 0}s all ease-in-out`};
  ${props => (props.visible ? props.animation : props.hide)}
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const fadeOut = css`
  opacity: 0;
`;
const fadeIn = css`
  opacity: 1;
`;
const slideOut = css`
  transform: translateX(-100%);
`;
const slideIn = css`
  transform: translateX(0%);
`;

class Login extends Component {
  static contextType = loadingContext;
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      error: '',
      password: '',
      verificationCode: '',
      resetVisible: false,
      resetResponse: false,
      email: '',
      submitting: false,
      checkAuthSucceeded: false,
      loginMethod: this.handleCheckAuth,
      currentLoginAttempt: 0,
      maxLoginAttempts: 4,
      mFA: null,
    };
  }

  componentDidMount() {
    if (window.Cypress) {
      window.login = this.props.login;
      window.appReady = true;
    }
  }

  handleChange = value => {
    this.setState(value);
  };

  toggleResetPassword = () => {
    this.setState({
      resetVisible: !this.state.resetVisible,
      resetResponse: false,
    });
  };

  handleReset = async () => {
    const { email } = this.state; // tmp
    const { BASE_PATH } = this.props; // tmp
    const res = await fetch(`${BASE_PATH}/api/reset-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (res) {
      this.setState({ resetResponse: true });
      if (window.Cypress) {
        return res;
      }
    }
  };

  handleCheckAuth = async () => {
    const { withLoader } = this.context;
    const { username, password, checkAuthSucceeded } = this.state;
    if (username.length && password.length && !checkAuthSucceeded) {
      this.setState({ submitting: true });
      const { credsAreValid, mFA, failedLogin } = await withLoader(checkAuth)({
        username,
        password,
      });
      this.setState({
        mFA: mFA,
        checkAuthSucceeded: credsAreValid,
        currentLoginAttempt: failedLogin,
        submitting: false,
      });
      //start login if no mfa required
      if (!mFA && credsAreValid) {
        await this.handleLogin();
      }
      // catch incase handleCheckAuth is invoked but we already went through this flow
    } else if (checkAuthSucceeded) {
      await this.handleLogin();
    } else {
      toast.error('Username and password are required fields', {
        position: 'top-center',
      });
    }
    this.setState({ submitting: false });
  };

  handleLogin = async () => {
    const { withLoader } = this.context;
    const { username, password, verificationCode, mFA } = this.state;
    // TODO: state needs to be updated to track if MFA/2FA is handled and run appropriate methods.
    if (username.length && password.length) {
      if (/[0-9]{6}/.test(verificationCode) && mFA) {
        this.setState({ submitting: true });
        toast('Logging in...', {
          position: 'top-center',
        });
        await withLoader(this.props.login)({
          username,
          password,
          verificationCode,
        });
        this.setState({ submitting: false });
      } else {
        // TODO: remove below and replace with approriate error handling
        this.setState({ submitting: true });
        toast('Logging in...', { position: 'top-center' });
        await withLoader(this.props.login)({
          username,
          password,
        });
      }
    } else {
      this.setState({ submitting: false });
      toast.error('Username and password are required fields', {
        position: 'top-center',
      });
    }
    this.setState({ submitting: false });
  };

  render() {
    return (
      <Wrapper>
        {this.state.currentLoginAttempt > 0 &&
          this.state.currentLoginAttempt < this.state.maxLoginAttempts && (
            <LoginMessageContainer>
              Login Attempt {this.state.currentLoginAttempt}/
              {this.state.maxLoginAttempts}
            </LoginMessageContainer>
          )}
        {this.state.currentLoginAttempt >= this.state.maxLoginAttempts ? (
          <FormWrapper visible={true}>
            <Heading>Login</Heading>
            <LoginMessageContainer>
              Login Attempt {this.state.currentLoginAttempt}/
              {this.state.maxLoginAttempts}. Please contact your system
              administrator.
            </LoginMessageContainer>
          </FormWrapper>
        ) : (
          <>
            <FormWrapper visible={!this.state.resetVisible}>
              <Heading>Login</Heading>
              <Form visible="true" onChange={this.handleChange}>
                <FormTransition>
                  <Transition
                    justify="space-between"
                    visible={!this.state.checkAuthSucceeded || !this.state.mFA}
                    animation={fadeIn}
                    hide={fadeOut}
                    position="relative"
                  >
                    <Input
                      htmlFor="username"
                      placeholder="Username"
                      name="username"
                      required
                      type="text"
                      disabled={this.state.checkAuthSucceeded}
                      data-cy="username_input"
                    />
                    <ForgotPasswordWrapper>
                      <Link onClick={this.toggleResetPassword}>
                        Forgot password?
                      </Link>
                    </ForgotPasswordWrapper>
                    <Input
                      htmlFor="password"
                      placeholder="Password"
                      name="password"
                      required
                      type="password"
                      disabled={this.state.checkAuthSucceeded}
                      data-cy="password_input"
                    />
                    <Flex
                      justify="space-between"
                      align="flex-start"
                      style={{ maxWidth: 400, paddingTop: 18 }}
                      direction="column"
                    >
                      <Button
                        text={
                          this.state.submitting ? 'Submitting...' : 'Submit'
                        }
                        disabled={this.state.submitting}
                        type="submit"
                        onClick={this.state.loginMethod}
                        style={{
                          width: '100%',
                        }}
                        data-cy="login_button"
                      />
                    </Flex>
                  </Transition>
                  <Transition
                    justify="space-between"
                    visible={this.state.checkAuthSucceeded && this.state.mFA}
                    animation={slideIn}
                    hide={slideOut}
                  >
                    <MfaTelValidate
                      mFA={this.state.mFA}
                      password={this.state.password}
                      email={this.state.username}
                      onChange={value => this.setState(value)}
                      onComplete={this.state.loginMethod}
                      setup={this.state.mFA && this.state.checkAuthSucceeded}
                    />
                  </Transition>
                </FormTransition>
              </Form>
            </FormWrapper>

            <FormWrapper visible={this.state.resetVisible}>
              <Box pad="small">
                <Heading level={3}>Reset Password</Heading>
              </Box>
              <Form
                visible={(!this.state.resetResponse).toString()}
                validate="submit"
                onSubmit={this.handleReset}
                onChange={this.handleChange}
              >
                <Input
                  htmlFor="email"
                  label="email"
                  name="email"
                  required
                  validate={value => {
                    const regex = new RegExp(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
                    );
                    if (value && !regex.exec(value)) {
                      return 'Must be a valid email.';
                    }
                  }}
                />
                <Box direction="row">
                  <Button
                    handleClick={this.toggleResetPassword}
                    margin={{ right: '1rem' }}
                    text="Cancel"
                  />
                  <Button
                    primary
                    type="submit"
                    text={
                      this.state.checkAuthSucceeded
                        ? 'Submit Verification Code'
                        : 'Submit'
                    }
                  />
                </Box>
              </Form>

              <ResetResponse visible={this.state.resetResponse}>
                <Message>
                  If the email is valid we'll send a password reset link to the
                  email you provided.
                </Message>

                <Button
                  text="Go back to login"
                  handleClick={this.toggleResetPassword}
                />
              </ResetResponse>
            </FormWrapper>
          </>
        )}
      </Wrapper>
    );
  }
}

export default connect(
  ({ auth }) => ({
    hasToken: !!auth.token,
    isAdmin: auth.isAdmin,
  }),
  { login }
)(Login);
